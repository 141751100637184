import type { CustomFieldFileValueType } from '@/common/components/CustomFieldItem';
import {
  getCustomFieldDateValues,
  getCustomFieldDatetimeValues,
  getCustomFieldFloatValues,
  getCustomFieldIntValues,
  getCustomFieldSelectValues,
  getCustomFieldTextValues,
  validateDateRequired,
  validateDatetimeRequired,
  validateFileRequired,
  validateFloatRequired,
  validateIntRequired,
  validateSelectRequired,
  validateTextRequired,
  validateUserRequired,
} from '@/common/components/CustomFieldItem';
import {
  getCustomFieldFileValues,
  getCustomFieldUserValues,
} from '@/common/components/CustomFieldItem/internal/CustomFieldItem.getValue';
import { useConfirmModal } from '@/context/ConfirmModalContext';
import { useTemplateContext } from '@/context/TemplateContext';
import { WorkOrderStatus } from '@/graphql/types';
import { toUserInputs } from '@/modules/users';
import { WorkOrderFormDataType } from '@/modules/workOrders/hooks/useAddWorkOrder';
import {
  IWorkOrderCustomFieldDateValueInput,
  IWorkOrderCustomFieldDatetimeValueInput,
  IWorkOrderCustomFieldFloatValueInput,
  IWorkOrderCustomFieldIntValueInput,
  IWorkOrderCustomFieldSelectValueInput,
  IWorkOrderCustomFieldTextValueInput,
  IWorkOrderCustomFieldUserValueInput,
  WorkOrderDoneStatus,
} from '@/modules/workOrders/types/workOrder';
import { IWorkOrderTemplateBase } from '@/modules/workOrders/types/workOrderTemplate';
import { convertCustomFieldValueToInputValue } from '@/modules/workOrders/utils/customFields';
import { useToast } from '@/utils/atoms/toast';
import {
  CheckListFormValueType,
  generateCheckListInitFormValue,
} from '@/utils/customFields/checkListCustomFields';
import { formatDateToYYYYMMDDForInput, formatDateToYYYYMMDDHHmmForInput } from '@/utils/date/date';
import { useFiles } from '@/utils/file/useFiles';
import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { assertNever } from '@/utils/types/types';
import { useCallback, useMemo, useState } from 'react';
import { SubmitErrorHandler, useForm } from 'react-hook-form';
import { WORK_ORDER_FORM_DEFAULT_VALUES } from './WorkOrderForm.constants';
import { useWorkOrderFormQuery } from './WorkOrderForm.generated';
import { WorkOrderFormProps, WorkOrderFormType } from './WorkOrderForm.types';

type UseWorkOrderFormProps = WorkOrderFormProps;

export const useWorkOrderForm = (props: UseWorkOrderFormProps) => {
  const {
    submit,
    pause,
    cancel,
    onAddCustomFieldFileValues,
    onRemoveCustomFieldFileValue,
    onUpdateCustomFieldFileValue,
    workOrder,
    customFieldFileValues = [],
    shouldConfirmStatusChange = false,
    checkListTemplate,
  } = props;

  const { t, t_errors } = useTranslation(TASK);
  const { confirm } = useConfirmModal();
  const { getFileUploadUrls, cacheFileSrc } = useFiles();

  const { defaultWorkOrderTemplate } = useTemplateContext();

  const template = useMemo(() => {
    return (props.template || defaultWorkOrderTemplate) as IWorkOrderTemplateBase;
  }, [props.template, defaultWorkOrderTemplate]);

  const { customFields, fieldOrders } = template;

  const { data: formData } = useWorkOrderFormQuery({
    fetchPolicy: 'cache-and-network',
  });

  const initialParts = () => {
    return (workOrder?.parts ?? []).map((entry) => {
      if (!entry.part) throw new Error(t_errors('impossible'));
      return {
        partId: entry.part.id,
        name: entry.part.name,
        stock: entry.part.stock,
        quantity: entry.quantity,
        unit: entry.part.unit || undefined,
        cost: entry.part.cost || undefined,
      };
    });
  };

  const initialCheckLists = () => {
    return (workOrder?.checkLists ?? []).map((entry) => {
      return {
        id: entry.id,
        name: entry.template.name,
        templateId: entry.template.id,
      };
    });
  };

  const initialAssignees = () => {
    if (workOrder) {
      return toUserInputs(workOrder.assignees);
    }
    return [];
  };

  const [status, setStatus] = useState<WorkOrderStatus>(workOrder?.status || 'open');

  const {
    customFieldTextValues,
    customFieldIntValues,
    customFieldFloatValues,
    customFieldSelectValues,
    customFieldDateValues,
    customFieldDatetimeValues,
    customFieldUserValues,
  } = convertCustomFieldValueToInputValue(workOrder || {});

  const [customFieldTextLocalValues, setCustomFieldTextLocalValues] =
    useState<IWorkOrderCustomFieldTextValueInput[]>(customFieldTextValues);
  const [customFieldIntLocalValues, setCustomFieldIntLocalValues] =
    useState<IWorkOrderCustomFieldIntValueInput[]>(customFieldIntValues);
  const [customFieldFloatLocalValues, setCustomFieldFloatLocalValues] =
    useState<IWorkOrderCustomFieldFloatValueInput[]>(customFieldFloatValues);
  const [customFieldSelectLocalValues, setCustomFieldSelectLocalValues] =
    useState<IWorkOrderCustomFieldSelectValueInput[]>(customFieldSelectValues);
  const [customFieldDatetimeLocalValues, setCustomFieldDatetimeLocalValues] =
    useState<IWorkOrderCustomFieldDatetimeValueInput[]>(customFieldDatetimeValues);
  const [customFieldDateLocalValues, setCustomFieldDateLocalValues] =
    useState<IWorkOrderCustomFieldDateValueInput[]>(customFieldDateValues);
  const [customFieldUserLocalValues, setCustomFieldUserLocalValues] =
    useState<IWorkOrderCustomFieldUserValueInput[]>(customFieldUserValues);
  const [customFieldFileLocalValues, setLocalCustomFieldFileValues] =
    useState<CustomFieldFileValueType[]>(customFieldFileValues);

  const [checkListFormValue, setCheckListFormValue] = useState<CheckListFormValueType>(
    generateCheckListInitFormValue()
  );

  const onChangeCheckListFormValue = (checkListFormValue: CheckListFormValueType) => {
    setCheckListFormValue(checkListFormValue);
  };

  const handleAddCustomFieldFileValues = useCallback(
    async (fileValues: CustomFieldFileValueType[]) => {
      if (!onAddCustomFieldFileValues || (await onAddCustomFieldFileValues(fileValues))) {
        setLocalCustomFieldFileValues((prev) => [...prev, ...fileValues]);
        fileValues.forEach((file) => cacheFileSrc(file.fileId, file.src));
      }
    },
    [cacheFileSrc, onAddCustomFieldFileValues]
  );

  const handleRemoveCustomFieldFileValue = async (fileId: string, customFieldId: number) => {
    if (
      !onRemoveCustomFieldFileValue ||
      (await onRemoveCustomFieldFileValue(fileId, customFieldId))
    ) {
      setLocalCustomFieldFileValues((values) => {
        const index = values.findIndex((value) => value.fileId === fileId);
        values.splice(index, 1);
        return [...values];
      });
    }
  };

  const handleUpdateCustomFieldFileValue = useCallback(
    async (
      fileId: string,
      params: {
        fileId: string;
        contentType: string;
        name: string;
        src: string;
      }
    ) => {
      await onUpdateCustomFieldFileValue?.(fileId, params);
      setLocalCustomFieldFileValues((prev) => {
        return prev.map((entry) =>
          entry.fileId === fileId ? { ...entry, ...params, key: entry.fileId } : entry
        );
      });
      cacheFileSrc(params.fileId, params.src);
    },
    [cacheFileSrc, onUpdateCustomFieldFileValue]
  );

  const methods = useForm<WorkOrderFormType>({
    defaultValues: {
      title: workOrder?.title ?? WORK_ORDER_FORM_DEFAULT_VALUES.title,
      assetId: workOrder?.assetId ?? WORK_ORDER_FORM_DEFAULT_VALUES.assetId,
      productId: workOrder?.productId ?? WORK_ORDER_FORM_DEFAULT_VALUES.productId,
      description: workOrder?.description ?? WORK_ORDER_FORM_DEFAULT_VALUES.description,
      stoppageStartAt: workOrder?.stoppage?.startAt
        ? formatDateToYYYYMMDDHHmmForInput(workOrder.stoppage.startAt)
        : WORK_ORDER_FORM_DEFAULT_VALUES.stoppageStartAt,
      stoppageEndAt: workOrder?.stoppage?.endAt
        ? formatDateToYYYYMMDDHHmmForInput(workOrder.stoppage.endAt)
        : WORK_ORDER_FORM_DEFAULT_VALUES.stoppageEndAt,
      dueDate: workOrder?.dueDate
        ? formatDateToYYYYMMDDForInput(workOrder.dueDate)
        : WORK_ORDER_FORM_DEFAULT_VALUES.dueDate,
      stoppageReasonId:
        workOrder?.stoppageReason?.id ?? WORK_ORDER_FORM_DEFAULT_VALUES.stoppageReasonId,
      assignees: initialAssignees(),
      priority: workOrder?.priority ?? WORK_ORDER_FORM_DEFAULT_VALUES.priority,
      parts: initialParts(),
      checkLists: initialCheckLists(),
    },
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    getValues,
  } = methods;

  const createAndValidateWorkOrderFromValue = (
    values: WorkOrderFormType
  ): { workOrder: WorkOrderFormDataType; errorLabels: string[] } => {
    const {
      title,
      assetId: nullableAssetId,
      productId: nullableProductId,
      priority,
      description,
      stoppageStartAt,
      stoppageEndAt,
      dueDate,
      stoppageReasonId,
      assignees,
      parts,
      checkLists,
    } = values;

    const assetId = nullableAssetId ?? undefined;
    const productId = nullableProductId ?? undefined;

    const errorLabels: string[] = [];

    const localOrderCustomFieldFileValues = customFieldFileLocalValues.map((entry) => {
      return {
        customFieldId: entry.customFieldId,
        fileId: entry.fileId,
        contentType: entry.contentType,
        name: entry.name,
      };
    });

    if (status === WorkOrderStatus.Done) {
      fieldOrders.forEach((fieldOrder) => {
        if (fieldOrder.type !== 'customField') return;
        const { customFieldId, requiredOnDone } = fieldOrder;
        if (!customFieldId) return;
        const customField = customFields.find(({ id }) => id === customFieldId);
        if (!customField) return;
        const { type, label } = customField;

        switch (type) {
          case 'text':
            const value = getCustomFieldTextValues(customFieldId, customFieldTextLocalValues);
            if (requiredOnDone && !validateTextRequired(value)) {
              errorLabels.push(label);
            }
            break;
          case 'int':
            const intValue = getCustomFieldIntValues(customFieldId, customFieldIntLocalValues);
            if (requiredOnDone && !validateIntRequired(intValue)) {
              errorLabels.push(label);
            }
            break;
          case 'float':
            const floatValue = getCustomFieldFloatValues(
              customFieldId,
              customFieldFloatLocalValues
            );
            if (requiredOnDone && !validateFloatRequired(floatValue)) {
              errorLabels.push(label);
            }
            break;
          case 'select':
            const selectedOptions = getCustomFieldSelectValues(
              customFieldId,
              customFieldSelectLocalValues
            );
            if (requiredOnDone && !validateSelectRequired(selectedOptions)) {
              errorLabels.push(label);
            }
            break;
          case 'datetime':
            const datetimeValue = getCustomFieldDatetimeValues(
              customFieldId,
              customFieldDatetimeLocalValues
            );
            if (requiredOnDone && !validateDatetimeRequired(datetimeValue)) {
              errorLabels.push(label);
            }
            break;
          case 'date':
            const dateValue = getCustomFieldDateValues(customFieldId, customFieldDateLocalValues);
            if (requiredOnDone && !validateDateRequired(dateValue)) {
              errorLabels.push(label);
            }
            break;
          case 'user':
            const user = getCustomFieldUserValues(customFieldId, customFieldUserLocalValues);
            if (requiredOnDone && !validateUserRequired(user)) {
              errorLabels.push(label);
            }
            break;
          case 'file':
            const files = getCustomFieldFileValues(customFieldId, customFieldFileLocalValues);
            if (requiredOnDone && !validateFileRequired(files)) {
              errorLabels.push(label);
            }
            break;
          case 'input':
            throw new Error('input type is not supported');
          default:
            assertNever(type);
        }
      });
    }

    const customFieldStampValues = checkListFormValue.customFieldStampValues.map(
      (customFieldStampValue) => {
        return {
          customFieldId: customFieldStampValue.customFieldId,
          stampedById: customFieldStampValue.stampedBy.id,
          stampedAt: customFieldStampValue.stampedAt,
        };
      }
    );

    const workOrder: WorkOrderFormDataType = {
      title,
      assetId,
      productId,
      description,
      status,
      dueDate,
      assignees,
      priority,
      customFieldTextValues: customFieldTextLocalValues,
      customFieldIntValues: customFieldIntLocalValues,
      customFieldFloatValues: customFieldFloatLocalValues,
      customFieldSelectValues: customFieldSelectLocalValues,
      customFieldDatetimeValues: customFieldDatetimeLocalValues,
      customFieldDateValues: customFieldDateLocalValues,
      customFieldUserValues: customFieldUserLocalValues,
      stoppage: {
        startAt: stoppageStartAt ? new Date(stoppageStartAt) : undefined,
        endAt: stoppageEndAt ? new Date(stoppageEndAt) : undefined,
      },
      stoppageReasonId: stoppageReasonId ?? undefined,
      parts: parts.map((part) => {
        return { partId: part.partId, quantity: part.quantity };
      }),
      customFieldFileValues: localOrderCustomFieldFileValues,
      checkLists: checkListTemplate
        ? [{ templateId: checkListTemplate.id, ...checkListFormValue, customFieldStampValues }]
        : // NOTE: チェックリストを追加する場合はtemplateIdのみを追加し、値は送らない
          checkLists.map(({ templateId }) => ({ templateId })),
      templateId: template.id,
    };

    return { workOrder, errorLabels };
  };

  const { toast } = useToast();
  const onSubmit = (values: WorkOrderFormType) => {
    const { workOrder, errorLabels } = createAndValidateWorkOrderFromValue(values);
    // NOTE: カスタムフィールドはRHFで管理されていないので、エラー時にもonSubmitに到達する
    if (errorLabels.length > 0) {
      toast({
        title: t('form.validation.required'),
        description: errorLabels.join('\n'),
        status: 'error',
      });
      return;
    }
    submit(workOrder);
  };

  const getDefaultFieldLabel = (key: keyof WorkOrderFormType) => {
    switch (key) {
      case 'title':
        return t('title');
      case 'assetId':
        return t('pages.asset');
      case 'productId':
        return t('pages.product');
      case 'description':
        return t('description');
      case 'dueDate':
        return t('date.due-date');
      case 'stoppageStartAt':
        return t('date.down-time-start-time');
      case 'stoppageEndAt':
        return t('date.down-time-end-time');
      case 'stoppageReasonId':
        return t('stoppage-reason.title');
      case 'assignees':
        return t('assignee');
      case 'status':
        return t('status.task-status');
      case 'priority':
        return t('priority.title');
      case 'parts':
        return t('pages.parts');
      case 'checkLists':
        return t('pages.check-list');
      default:
        assertNever(key);
    }
  };
  const onError: SubmitErrorHandler<WorkOrderFormType> = (errors) => {
    const errorLabels = (Object.keys(errors) as (keyof WorkOrderFormType)[]).map((key) =>
      getDefaultFieldLabel(key)
    );
    // NOTE: デフォルト項目はRHFで管理されているので、エラー時にもonErrorに到達する
    if (errorLabels.length === 0) return;
    toast({
      title: t('form.validation.required'),
      description: errorLabels.join('\n'),
      status: 'error',
    });
  };

  const onChangeWorkOrderStatus = async (nextStatus: WorkOrderStatus) => {
    if (
      shouldConfirmStatusChange &&
      status === WorkOrderDoneStatus &&
      nextStatus !== WorkOrderDoneStatus
    ) {
      const result = await confirm(
        t('confirmation.task.revert-done-task'),
        undefined,
        t('actions.mark-as-incomplete'),
        {
          colorScheme: 'primary',
        },
        t('actions.cancel')
      );
      if (!result) {
        return;
      }
    }
    if (nextStatus !== WorkOrderDoneStatus) {
      // NOTE: registerでバリデーションを登録する際のrequiredによる条件分岐を間に合わせるため、ステータス変更の次のタスクキューでトリガーする
      setTimeout(methods.trigger, 0);
    }
    setStatus(nextStatus);
  };

  const handleCancel = () => {
    cancel();
  };

  const handlePause = () => {
    if (!pause) throw new Error('pause is not defined');

    const values = getValues();
    const { workOrder, errorLabels } = createAndValidateWorkOrderFromValue(values);
    if (errorLabels.length > 0) {
      toast({
        title: t('form.validation.required'),
        description: errorLabels.join('\n'),
        status: 'error',
      });
      return;
    }
    pause(workOrder);
  };

  return {
    handleCancel,
    handlePause,
    customFields,
    onSubmit,
    onError,
    onChangeWorkOrderStatus,
    onChangeCheckListFormValue,
    handleAddCustomFieldFileValues,
    handleRemoveCustomFieldFileValue,
    handleUpdateCustomFieldFileValue,
    errors,
    handleSubmit,
    register,
    formData,
    fieldOrders,
    setCustomFieldTextLocalValues,
    setCustomFieldIntLocalValues,
    setCustomFieldFloatLocalValues,
    setCustomFieldSelectLocalValues,
    setCustomFieldDatetimeLocalValues,
    setCustomFieldDateLocalValues,
    setCustomFieldUserLocalValues,
    customFieldTextLocalValues,
    customFieldIntLocalValues,
    customFieldFloatLocalValues,
    customFieldSelectLocalValues,
    customFieldDatetimeLocalValues,
    customFieldDateLocalValues,
    customFieldUserLocalValues,
    customFieldFileLocalValues,
    checkListFormValue,
    methods,
    isSubmitting,
    getFileUploadUrls,
    status,
    getDefaultFieldLabel,
  } as const;
};
