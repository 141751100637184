import { InputTag, InputTagProps } from '@/common/components/InputTag';
import { Maybe } from '@/common/types';
import { usePartDetailModal } from '@/modules/parts/components/PartDetailModal';
import { useToast } from '@/utils/atoms/toast';
import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useCallback, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useWorkOrderPartFieldPartLazyQuery } from './WorkOrderPartField.generated';
import WorkOrderPartFieldCard from './WorkOrderPartFieldCard';
import WorkOrderPartFieldChangeStockModal from './WorkOrderPartFieldChangeStockModal';
import WorkOrderPartFieldSelectModal from './WorkOrderPartFieldSelectModal';

const initialQuantity = 1;
const deleteQuantity = 0;

export type WorkOrderPartCardType = {
  name: string;
  partId: number;
  quantity: number;
  stock: number;
  unit?: string;
  cost?: number;
  currencyCode?: string;
};

export type WorkOrderPartFieldProps = {
  isEdit?: boolean;
  workOrderId: Maybe<number>;
  assetId: Maybe<number>;
  workOrderParts: WorkOrderPartCardType[];
  onChangeWorkOrderPart: (parts: WorkOrderPartCardType[]) => void;
  onUpdatePart?: (partId: number, quantity: number) => void;
  isPartRegisterModalOpen?: boolean;
  onPartRegisterModalOpen?: () => void;
  onPartRegisterModalClose?: () => void;
  inputTagProps?: InputTagProps;
  errorMessage?: string;
};

gql`
  fragment WorkOrderPartField_Part on Part {
    id
    name
    stock
    unit
    cost
    currencyCode
  }
`;

gql`
  query WorkOrderPartFieldPart($id: Int!) {
    part(id: $id) {
      ...WorkOrderPartField_Part
    }
  }
`;

const WorkOrderPartField: FC<WorkOrderPartFieldProps> = (props: WorkOrderPartFieldProps) => {
  const { toast } = useToast();
  const {
    workOrderId,
    isEdit = true,
    assetId,
    workOrderParts,
    onUpdatePart,
    onChangeWorkOrderPart,
    isPartRegisterModalOpen,
    onPartRegisterModalOpen,
    onPartRegisterModalClose,
    inputTagProps,
    errorMessage,
  } = props;

  const { t, t_toasts } = useTranslation();
  const [getPart] = useWorkOrderPartFieldPartLazyQuery();

  const {
    isOpen: isPartListOpen,
    onOpen: onPartListOpen,
    onClose: onPartListClose,
  } = useDisclosure({
    isOpen: isPartRegisterModalOpen,
    onOpen: onPartRegisterModalOpen,
    onClose: onPartRegisterModalClose,
  });

  const {
    isOpen: isChangeStockModalOpen,
    onOpen: onChangeStockModalOpen,
    onClose: onChangeStockModalClose,
  } = useDisclosure();

  const [targetPart, setTargetPart] = useState<WorkOrderPartCardType | undefined>();

  const onClickAddPart = () => {
    onPartListOpen();
  };

  const handlePartClicked = async (partId: number) => {
    const { data } = await getPart({ variables: { id: partId } });
    if (!data?.part) throw new Error('Part not found');
    const part = data.part;
    if (part.stock <= 0) {
      toast({
        title: `0${part.unit || ''} ${t_toasts('failed.out-of-stock-not-added')}`,
        status: 'error',
      });
      return;
    }

    if (workOrderParts.some((entry) => entry.partId === part.id)) {
      toast({
        title: t_toasts('failed.part.already-added'),
        status: 'error',
      });
      return;
    }

    const workOrderPart = {
      partId: partId,
      name: part.name,
      stock: part.stock - initialQuantity, // すでに使用しているので、1つ減らしておく
      quantity: initialQuantity,
      unit: part.unit || undefined,
      cost: part.cost || undefined,
    };

    onChangeWorkOrderPart([...workOrderParts, workOrderPart]);
    if (workOrderId && onUpdatePart) onUpdatePart(partId, initialQuantity);
    onPartListClose();
  };

  const handleWorkOrderPartClicked = (partId: number) => {
    const part = workOrderParts.find((entry) => entry.partId === partId);
    if (!part) throw new Error('Part not found');
    setTargetPart(part);
    onChangeStockModalOpen();
  };

  const closeChangeStockModal = useCallback(() => {
    // WorkOrderPartFieldChangeStockModalを初期化するために、setTargetPartしている
    setTargetPart(undefined);
    onChangeStockModalClose();
  }, [onChangeStockModalClose]);

  const onConfirmPartButtonClick = useCallback(
    (partId: number, quantity: number) => {
      const parts = workOrderParts.map((part) =>
        part.partId === partId
          ? { ...part, stock: part.stock + part.quantity - quantity, quantity } // 編集の場合は使った在庫を戻して、今回使用した在庫をひく
          : part
      );
      onChangeWorkOrderPart(parts);
      if (workOrderId && onUpdatePart) onUpdatePart(partId, quantity);
      closeChangeStockModal();
    },
    [closeChangeStockModal, onChangeWorkOrderPart, onUpdatePart, workOrderId, workOrderParts]
  );

  const onDeletePartButtonClick = useCallback(
    (partId: number) => {
      const findIndex = workOrderParts.findIndex((part) => part.partId === partId);
      workOrderParts.splice(findIndex, 1);
      onChangeWorkOrderPart([...workOrderParts]);
      if (workOrderId && onUpdatePart) onUpdatePart(partId, deleteQuantity);
      closeChangeStockModal();
    },
    [closeChangeStockModal, onChangeWorkOrderPart, onUpdatePart, workOrderId, workOrderParts]
  );

  const { element: partDetailModal, open: openPartDetailModal } = usePartDetailModal();

  return (
    <>
      {partDetailModal}
      {(isEdit || workOrderParts.length > 0) && (
        <FormControl
          isInvalid={!!errorMessage}
          bg='neutral.0'
          borderRadius='md'
          px={4}
          py={2}
          mx={2}
          my={4}
          width='auto'
        >
          <FormLabel fontWeight='bold'>
            <Flex gap={2} alignItems='center'>
              {t('pages.parts')}
              {inputTagProps && <InputTag {...inputTagProps} />}
            </Flex>
          </FormLabel>
          {workOrderParts.map((part) => (
            <WorkOrderPartFieldCard
              disabled={!isEdit}
              key={part.partId}
              part={part}
              onPartClicked={handleWorkOrderPartClicked}
              onPartNameClicked={(partId) => openPartDetailModal({ partId })}
            />
          ))}
          {isEdit && (
            <Button
              my={1}
              size='sm'
              variant='ghost'
              colorScheme='primary'
              onClick={onClickAddPart}
              leftIcon={<MdAdd />}
            >
              {t('actions.item-add')}
            </Button>
          )}
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
      )}
      <WorkOrderPartFieldSelectModal
        key={assetId} // assetが変更されたときに再レンダリングするため
        isOpen={isPartListOpen} // toggle show state with PartDetailModal
        onOpen={onPartListOpen}
        onClose={onPartListClose}
        workOrderAssetId={assetId}
        onPartClicked={handlePartClicked}
        onPartNameClicked={(partId) => openPartDetailModal({ partId })}
      />
      {targetPart && (
        <WorkOrderPartFieldChangeStockModal
          isOpen={isChangeStockModalOpen} // toggle show state with PartDetailModal
          onOpen={onChangeStockModalOpen}
          onClose={closeChangeStockModal}
          partId={targetPart.partId}
          stock={isEdit ? targetPart.stock + targetPart.quantity : targetPart.stock} // 編集時はすでに使った在庫を、差し戻した値を登録できるようにする。
          quantity={targetPart.quantity}
          onConfirmPartButtonClick={onConfirmPartButtonClick}
          onDeletePartButtonClick={onDeletePartButtonClick}
        />
      )}
    </>
  );
};

export default WorkOrderPartField;
