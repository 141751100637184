import { WorkOrderFormType } from './WorkOrderForm.types';

export const WORK_ORDER_FORM_DEFAULT_VALUES: WorkOrderFormType = {
  title: '',
  assetId: null,
  productId: null,
  description: '',
  dueDate: null,
  stoppageStartAt: null,
  stoppageEndAt: null,
  status: 'open',
  stoppageReasonId: null,
  assignees: [],
  priority: 'none',
  parts: [],
  checkLists: [],
};
